<template>
  <div class="article-items">
    <el-table :data="items" border style="width: 100%">
      <el-table-column label="#" prop="" width="40">
        <template slot-scope="scope">
          <!--   <div class="flex justify-between">
            <i
              @click="handleDelete(scope.$index)"
              class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
            ></i>
          </div> -->
          <span class="font-bold"> {{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="N° ARTICLE" prop="" width="100">
        <template slot-scope="{ row }">
          <span class="text-blue">{{ row.article_sku }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="article_name" label="DÉSIGNATION" min-width="250">
        <template slot-scope="scope">
          <div class="article-item-input">
            <el-select
              size="medium"
              style="width: 100%"
              filterable
              v-model="scope.row.article_id"
              remote
              :remote-method="searchArticle"
              :loading="loadingArticle"
              placeholder="Recherche un article"
              clearable
              @change="
                selectArticle(scope.row.article_id, scope.row, scope.$index)
              "
            >
              <el-option
                v-for="item in articles"
                :key="item.article_id"
                :label="` ${item.article_name}`"
                :value="item.article_id"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="ENTREPÔT" width="200">
        <template slot-scope="scope">
          <div class="article-item-input">
            <span
              v-if="loadingStock && scope.$index === rowIndex"
              v-loading="scope.$index === rowIndex"
              element-loading-spinner="el-icon-loading"
            ></span>
            <el-select
              v-model="scope.row.warehouse_code"
              class="w-full"
              size="medium"
              placeholder="selectionner l'entrepôt"
            >
              <el-option
                v-for="item in scope.row.stocks"
                :key="item.warehouse_id"
                :label="`${item.quantity} : ${item.warehouse_name}`"
                :value="item.warehouse_code"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        align="center"
        label="QUANTITE EN STOCK"
        width="150"
      >
        <template slot-scope="{ row }">
          <div class="input-transparent">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.quantity_onhand"
              :disabled="true"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        align="right"
        label="QUANTITÉ AJUSTÉE "
        width="200"
      >
        <template slot-scope="{ row }">
          <div class="article-item-input">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.quantity"
              @blur="handleModifyQuantity(row)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="quantity"
        align="right"
        label="NOUVELLE QUANTITE "
        width="200"
      >
        <template slot-scope="{ row }">
          <div class="article-item-input">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.quantity_new"
              @blur="handleModifyQuantityNew(row)"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" width="80">
        <template slot-scope="{ row }">
          <i
            @click="handleDelete(row.article_id)"
            class="el-icon-circle-close text-red-500 text-2xl cursor-pointer mr-2"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2">
      <el-button icon="el-icon-plus" type="infos" @click="addArticle"
        >Ajouter une ligne</el-button
      ><el-button icon="el-icon-plus" type="infos" @click="addFiveArticle"
        >Ajouter 5 lignes</el-button
      >
    </div>
    <!--
      <el-dialog
      title="SELECTIONNER LES ARTICLES"
      :visible.sync="dialogArticle"
      width="70%"
      top="1%"
    >
      <div class="flex el-dialog-div">
        <div class="w-1/2">
          <el-select
            class="w-full mb-2"
            v-model="listQuery.warehouse_id"
            placeholder="selectionner l'entrepôt "
          >
            <el-option
              v-for="item in warehouses"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            placeholder="Recherche un article"
            v-on:input.native="handleSearch"
            v-model="listQuery.name"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="list-article" :Loading="listLoading">
            <div class="item" v-for="(item, index) in listArticle" :key="index">
              <span>{{ item.article_name }}</span>
            </div>
          </div>
        </div>

        <div class="w-1/2">Article</div>
      </div>
    </el-dialog>  -->
  </div>
</template>

<script>
import { searchArticle } from "@/api/article";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import { fetchStockOnHand } from "@/api/stock";
export default {
  name: "Add-Article-AJUSTEMENT",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogArticle: false,

      articles: [],
      listArticle: [],
      loadingArticle: false,
      loadingStock: false,
    };
  },
  mounted() {},
  methods: {
    async searchArticle(query) {
      // console.log(query);
      if (query.length !== "" && query.length > 2) {
        this.loadingArticle = true;
        await searchArticle({ name: query })
          .then((res) => {
            this.articles = res.data;
            this.loadingArticle = false;
          })
          .catch((err) => {
            if (err.data.status === 400) {
              this.$message({
                message:
                  "Veuillez renseigner les informations obligatoire et les articles",
                type: "warning",
              });
            } else if (err.data.status === 409 || err.data.status === 404) {
              this.$message({
                message: err.data.message,
                type: "warning",
              });
            } else {
              this.$message({
                message: "Erreur serveur",
                type: "danger",
              });
            }
          });
      }
    },
    selectArticle(articleId, row, index) {
      const checkDuplicat = this.items.filter(
        (el) => el.article_id === articleId
      ).length;
      if (checkDuplicat > 1) {
        row.article_id = "";
        this.$message({
          message:
            "L'article a été dejà ajouter, veuillez  modifier la quantité",
          type: "warning",
        });
      } else {
        this.rowIndex = index;
        const item = this.articles.find((el) => el.article_id === articleId);
        console.log(item);
        row.article_id = item.article_id;
        row.article_name = item.article_name;
        row.article_sku = item.sku;

        this.fetchStockOnHand(row);
        // this.calculTotalItem(row);
      }
    },
    addArticle() {
      this.items.push({
        article_id: "",
        article_sku: "",
        article_name: "",
        description: "",
        quantity_onhand: 0,
        quantity_new: 0,
        quantity: 0,
        warehouse_code: "",
        stocks: [],
      });
    },
    addFiveArticle() {
      for (let i = 0; i < 4; i++) {
        this.addArticle();
      }
    },

    async fetchStockOnHand(row) {
      this.loadingStock = true;
      await fetchStockOnHand(row.article_id)
        .then((res) => {
          setTimeout(() => {
            row.stocks = res.data;
            const stock = row.stocks.find((el) => el.has_primary === true);
            row.warehouse_code = stock.warehouse_code;
            row.quantity_onhand = stock.quantity;
            this.loadingStock = false;
          }, 1000);
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },

    handleDelete(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },
    handleModifyQuantity(row) {
      row.quantity_new = row.quantity_onhand + row.quantity;
    },
    handleModifyQuantityNew(row) {
      row.quantity = row.quantity_new - row.quantity_onhand;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.subtotal {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-items: start;
  .item {
    display: flex;
    justify-content: space-between;

    border: 1px solid $borderColor;

    font-weight: bold;
    .label,
    .value {
      padding: 7px 5px;
    }
    .label {
      background: $bg-header-table;
      width: 50%;
    }
    .value {
      width: 50%;
      display: flex;
      justify-content: end;
    }
  }
  .total {
    color: $green-dark;
  }
}
.el-dialog-div {
  height: 70vh;
  overflow: hidden;
}
.list-article {
  height: 70vh;
  overflow-y: auto;
  .item {
    padding: 5px 0px;
    cursor: pointer;
    font-weight: 500;
  }
}
.article_name {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.article-desc {
  display: flex;
  padding-top: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
.el-select .el-input {
  width: 50px;
}
</style>
