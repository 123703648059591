<template>
  <div class="flex flex-col font-Inter px-10">
    <div class="lg:flex lg:items-center lg:justify-between mt-4 mb-3 py-2">
      <div class="mt-1 flex flex-col justify-center">
        <h2
          class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate uppercase"
        >
          Créer un ajustement de stock
        </h2>
        <!-- <p class="text-gray-400 text-xs mt-2 w-1/2 text-justify">
            Pour enregistrer un devis le numero est générer par le système et
            veuillez renseigner les informations obligatoire (
            <strong class="text-red-500">*</strong>) et vous pouvez a tout
            moment convertir le devis en facture ou bon de commande
          </p> -->
      </div>
    </div>

    <div class="bg-white shadow p-6">
      <el-form
        :model="adjustmentStock"
        :rules="rules"
        ref="adjustmentStockForm"
        :label-position="labelPosition"
        class="mb-6"
      >
        <el-form-item prop="raison" class="ml-2">
          <span slot="label" class="mr-7">Motif</span>
          <el-select
            v-model="adjustmentStock.raison"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="(item, index) in raisons"
              :key="index"
              :value="item"
              :label="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="warehouse_code" class="ml-2">
          <span slot="label" class="mr-7">Entrepôt</span>
          <el-select
            v-model="adjustmentStock.warehouse_code"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="(item, index) in warehouses"
              :key="index"
              :value="item.code"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="ml-2" style="width: 30%">
          <span slot="label" class="mr-7">Note</span>
          <el-input
            type="textarea"
            placeholder="Laisser une note"
            v-model="adjustmentStock.note"
            :autosize="{ minRows: 5 }"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="flex justify-end font-semibold mb-1">
        <span class="mr-2">Nombre d'article : </span>
        <span>{{ totalItems }}</span>
      </div>
      <add-article-adjustment
        :items="items"
        :warehouseFrom="adjustmentStock.warehouse_code"
      />
    </div>

    <page-footer>
      <div class="flex justify-between px-10 py-2">
        <div>
          <div
            class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            Article: {{ totalItems }}
          </div>
        </div>

        <div>
          <el-button
            type="primary"
            icon="el-icon-save"
            :loading="loading"
            @click="createData('adjustmentStockForm')"
            >Enregistrer</el-button
          >
        </div>
      </div>
    </page-footer>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";

import { createAdjustmentStock /*fetchWarehouse*/ } from "@/api/stock";
import AddArticleAdjustment from "@/components/AddArticleAdjustment";
import PageFooter from "@/components/PageFooter";
export default {
  name: "CreateAdjustmentStock",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticleAdjustment, PageFooter },

  data() {
    return {
      labelPosition: "top",
      componentLoanding: false,
      articles: [],
      warehouses: [],
      loading: false,
      articleName: "",
      loadingArticle: false,
      raisons: [
        "Recevoir des articles",
        "Recomptage des stocks",
        "Perte",
        "Dommages",
        "Eclatement",
      ],
      items: [
        {
          article_id: "",
          article_sku: "",
          article_name: "",
          description: "",
          quantity_onhand: 0,
          quantity_new: 0,
          quantity: 0,
          warehouse_code: "",
          stocks: [],
        },

        {
          article_id: "",
          article_sku: "",
          article_name: "",
          description: "",
          quantity_onhand: 0,
          quantity_new: 0,
          quantity: 0,
          warehouse_code: "",
          stocks: [],
        },

        {
          article_id: "",
          article_sku: "",
          article_name: "",
          description: "",
          quantity_onhand: 0,
          quantity_new: 0,
          quantity: 0,
          warehouse_code: "",
          stocks: [],
        },

        {
          article_id: "",
          article_sku: "",
          article_name: "",
          description: "",
          quantity_onhand: 0,
          quantity_new: 0,
          quantity: 0,
          warehouse_code: "",
          stocks: [],
        },
      ],
      adjustmentStock: {
        raison: "",
        note: "",
        items: [],
      },

      rules: {
        raison: [
          {
            required: true,
            message: "Le motif est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    totalItems: function () {
      return this.items.filter(
        (el) => el.article_id !== "" && el.article_id !== undefined
      ).length;
    },

    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
    //this.fetchWarehouse();
  },
  methods: {
    /* async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },*/

    createData(adjustmentStockForm) {
      this.$refs[adjustmentStockForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          console.log(this.items);
          this.items.forEach((el) => {
            if (
              (el.article_id !== "" || el.article_id !== undefined) &&
              el.quantity !== 0
            ) {
              el.stocks = undefined;
              el.article_name = undefined;
              el.description = undefined;
              el.quantity_onhand = undefined;
              el.quantity_new = undefined;
              this.adjustmentStock.items.push(el);
            }
          });

          createAdjustmentStock(this.adjustmentStock)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push(`/stock/index?tab=AJUSTMENT-STOCK`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style></style>
